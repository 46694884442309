<template>
  <div class="add-map">
    <div class="add-map__loading" v-if="loadingData">
      <Loader text="Loading..."></Loader>
    </div>
    <div v-if="step === 1">
      <div class="mb-20" :class="{ loading: uploadFilesLoading }">
        <a-upload-dragger
          name="file"
          accept="image/jpeg, image/png, .xlsx, .xls, .zip"
          :multiple="false"
          :showUploadList="false"
          :customRequest="uploadFiles"
          ref="upload"
        >
          <div>
            <font-awesome-icon icon="file-upload" v-if="true" />
            <font-awesome-icon icon="check-circle" v-else />
            <p>
              Please drag and drop or click to upload your files. <br />You must
              upload either processed data tables <strong>(Excel)</strong> OR a
              <strong>ZIP</strong> folder with your SPSS and tabplan. <br />You
              can also upload a brand logo (PNG or JPEG). The uploaded image is
              how this project will appear in the library going forward.
            </p>
            <div class="flex items-start justify-center mt-5">
              <span
                class="flex gap-5 items-center mr-10"
                :class="{ hidden: this.uploadFormData.imageFile === null }"
              >
                <font-awesome-icon
                  class="font-size-13 mb-0"
                  icon="check-circle"
                  style="color: #01c101"
                />
                Brand logo is uploaded.</span
              >
              <span
                class="flex gap-5 items-center mr-10"
                :class="{ hidden: this.uploadFormData.excelFile === null }"
                ><font-awesome-icon
                  class="font-size-13 mb-0"
                  icon="check-circle"
                  style="color: #01c101"
                />
                Data tables are uploaded.</span
              >
              <span
                class="flex gap-5 items-center"
                :class="{ hidden: this.uploadFormData.zipFile === null }"
                ><font-awesome-icon
                  class="font-size-13 mb-0"
                  icon="check-circle"
                  style="color: #01c101"
                />
                Tabplan and SPSS file are uploaded.</span
              >
            </div>
          </div>
        </a-upload-dragger>
      </div>

      <div class="flex gap-10 mb-20">
        <div class="input-group input-group--small w-full">
          <label>*Project name</label>
          <a-input
            class="w-full"
            v-model="uploadFormData.projectName"
            placeholder="Please enter a detailed project name"
          />
        </div>
        <div class="input-group input-group--small w-full">
          <label>*Project Number</label>
          <a-input
            class="w-full"
            v-model="uploadFormData.projectNumber"
            placeholder="Please enter your project number"
          />
        </div>
      </div>
      <div class="flex gap-10 mb-20">
        <div class="input-group input-group--small w-full">
          <label>*Map Type</label>
          <a-select
            @change="uploadFormData.mapType = $event"
            class="w-full"
            placeholder="Choose between Persona and Emoti"
            :default-value="
              uploadFormData.mapType === '' ? undefined : uploadFormData.mapType
            "
          >
            <a-select-option
              v-for="mapType in ['Persona', 'Emoti']"
              :key="mapType"
              :value="mapType"
            >
              {{ mapType }}
            </a-select-option>
          </a-select>
        </div>
        <div class="input-group input-group--small w-full">
          <label>*Date Fielded</label>

          <a-month-picker
            class="w-full"
            placeholder="Please select the date when this was fielded"
            @change="uploadFormData.dateFielded = $event"
            :default-value="
              uploadFormData.dateFielded === ''
                ? undefined
                : uploadFormData.dateFielded
            "
          />
        </div>
      </div>
      <div class="flex gap-10 mb-20">
        <div class="input-group input-group--small w-full">
          <label class="flex-i justify-between">*Market </label>
          <a-select
            show-search
            @change="uploadFormData.market = $event"
            class="w-full"
            placeholder="Please select the country this was fielded in"
            :default-value="
              uploadFormData.market === '' ? undefined : uploadFormData.market
            "
          >
            <a-select-option
              v-for="market in countriesList"
              :key="market"
              :value="market"
            >
              {{ market }}
            </a-select-option>
          </a-select>
        </div>
        <div class="input-group input-group--small w-full">
          <label>*Category</label>
          <a-select
            show-search
            @change="uploadFormData.category = $event"
            class="w-full"
            placeholder="Please select the category for this data"
            :default-value="
              uploadFormData.category === ''
                ? undefined
                : uploadFormData.category
            "
          >
            <a-select-option
              v-for="category in categoriesList"
              :key="category"
              :value="category"
            >
              {{ category }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="flex gap-10">
        <div class="input-group input-group--small w-full">
          <label>*Brands</label>

          <a-input-group compact>
            <a-input
              class="w-full mb-10"
              v-model="brand"
              @pressEnter="addBrand"
              placeholder="Please enter all the brands that are included in this data for tagging purposes"
            />
            <a-button @click="addBrand" :disabled="!brand">Add</a-button>
          </a-input-group>
          <div>
            <a-tag
              v-for="(item, index) in uploadFormData.brands"
              :key="index"
              closable
              @close="removeValue(index)"
              class="mb-5"
            >
              {{ item }}
            </a-tag>
          </div>
        </div>
      </div>
      <p class="text-white font-size-13 mt-10">*Required fields</p>

      <div class="flex mb-25">
        <div class="input-group input-group--small">
          <label>
            <a-checkbox v-model="isAdvancedSetting"
              >Advanced setting?</a-checkbox
            >
          </label>
        </div>
      </div>

      <div class="flex justify-end items-center mt-40">
        <div class="gap-10 flex">
          <a-button @click="$emit('close-modal')">Cancel</a-button>
          <a-button
            type="primary"
            @click="save"
            :disabled="disabledContinueBtn"
            :loading="loading"
            >Continue</a-button
          >
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex mb-25">
        <div class="input-group input-group--small">
          <label>
            <a-checkbox v-model="selectAllMaps" @change="toggleSelectAllMaps"
              >Select/Deselect All Maps</a-checkbox
            >
          </label>
        </div>
      </div>

      <div class="flex mb-25">
        <div class="input-group input-group--small flex-1">
          <label>Choose maps to download</label>
          <a-checkbox-group
            :options="mapsList"
            v-model="chosenDownloadMaps"
            class="flex flex-col"
          />
        </div>
        <div class="input-group input-group--small flex-1">
          <label>Choose Target Zones</label>
          <a-checkbox-group
            :options="targetZones"
            @change="setTargetZones"
            v-model="chosenTargetZones"
            class="flex flex-col"
          />
        </div>
        <div class="input-group input-group--small flex-1">
          <label>Choose Baseline map</label>
          <a-checkbox-group
            :options="mapsListBaseline"
            @change="setDownloadMapsBaseline"
            v-model="chosenBaselineMap"
            class="flex flex-col"
          />
        </div>
      </div>

      <div class="flex gap-15">
        <div class="input-group input-group--small mt-25" style="width: 220px">
          <label class="flex-i justify-between">Confidence level </label>
          <a-select
            @change="confidence = $event"
            class="w-full"
            placeholder="Please select the confidence level"
            :default-value="confidence === '' ? undefined : confidence"
            :key="Math.random()"
          >
            <a-select-option
              v-for="level in ['Small letter', 'Large letter']"
              :key="level"
              :value="level"
            >
              {{ level }}
            </a-select-option>
          </a-select>
        </div>

        <div class="input-group input-group--small">
          <span class="font-size-13 d-block mb-5"
            >Maintenance Fee: <strong><s>$600</s></strong
            >, for Beta Testing: <strong>$100</strong>.</span
          >
          <label class="flex-i justify-between" style="max-width: 220px"
            >Enter PO
            <a-tooltip>
              <template slot="title">
                To validate the PO, please enter a value and either click
                outside the field or press Enter.
              </template>
              <a-icon type="info-circle" /> </a-tooltip
          ></label>
          <div class="flex items-center">
            <a-input
              v-model="po"
              placeholder="Please enter PO"
              style="max-width: 220px"
              class="w-full"
              @blur="checkPo"
              @keyup.enter="checkPo"
            />

            <a-tooltip placement="top">
              <template slot="title"> PO is valid </template>
              <font-awesome-icon
                class="ml-5 font-size-16 cursor-pointer"
                icon="check-circle"
                style="color: #01c101"
                v-if="
                  this.lastValidPo === this.po &&
                  this.po !== null &&
                  this.lastValidPo !== null
                "
              />
            </a-tooltip>
            <Loader v-if="loadingCheckPo" class="small-loader"></Loader>
          </div>
        </div>

        <div class="input-group input-group--small mt-25" style="width: 220px">
          <label class="flex-i justify-between">Purpose </label>
          <a-select
            @change="purpose = $event"
            class="w-full"
            placeholder="Please select Purpose"
            :default-value="purpose === '' ? undefined : purpose"
            :key="Math.random()"
          >
            <a-select-option
              v-for="purposeType in ['Biz Dev', 'Client Paid']"
              :key="purposeType"
              :value="purposeType"
            >
              {{ purposeType }}
            </a-select-option>
          </a-select>
        </div>
      </div>

      <p class="text-white font-size-13 mt-20">*All fields are required</p>

      <div class="flex mb-25">
        <div class="input-group input-group--small">
          <label>
            <a-checkbox v-model="isDuplicated">Is Duplicated?</a-checkbox>
          </label>
        </div>
      </div>

      <div class="flex justify-between items-center mt-40">
        <div>
          <a-button
            type="link"
            @click="step = 1"
            class="flex items-center gap-5"
            v-if="actionType !== 'modify'"
          >
            <icon-base
              :width="20"
              :height="20"
              :viewBox1="20"
              :viewBox2="20"
              iconName="longArrowRight"
              style="transform: rotate(180deg)"
              ><icon-long-arrow-right /></icon-base
            >Back</a-button
          >
        </div>
        <div class="gap-10 flex">
          <a-button @click="$emit('close-modal')">Cancel</a-button>
          <a-button
            type="primary"
            @click="download"
            :disabled="disabledDownloadBtn"
            :loading="loadingDownload"
            >Download</a-button
          >
        </div>
      </div>
      <a-modal
        :visible="showDownloadModal"
        @cancel="showDownloadModal = false"
        :footer="null"
        :width="555"
        centered
        :maskClosable="false"
      >
        <Loader
          text="Please wait while we prepare your maps..."
          class="mt-40"
        ></Loader>
        <div class="flex items-center justify-center mt-20">
          <p>
            If you want to cancel downloding process, please press Cancel
            Download OR you can exit this window and the maps will be sent to
            your email when finished
          </p>
        </div>
        <div class="flex items-center justify-center">
          <a-button @click="cancelDownload"> Cancel Download</a-button>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/general/IconBase.vue";
import IconLongArrowRight from "@/components/icons/IconLongArrowRight.vue";
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import _ from "lodash";
import JSZip from "jszip";
import Loader from "@/components/general/Loader.vue";
import { gtagMixin } from "@/mixins/gtagMixin";

export default {
  name: "AddMapModal",
  components: { IconBase, IconLongArrowRight, Loader },
  props: {
    categoriesList: {
      type: Array,
    },
    countriesList: {
      type: Array,
    },
    chosenProject: {
      type: Object,
    },
    actionType: {
      type: String,
    },
  },
  mixins: [gtagMixin],
  data() {
    return {
      uploadFilesLoading: false,
      uploadFormData: {
        projectName: "",
        projectNumber: "",
        mapType: "",
        category: "",
        market: "",
        brands: [],
        dateFielded: "",
        imageFile: null,
        excelFile: null,
        zipFile: null,
      },
      brand: "",
      step: 1,
      targetZones: [
        {
          value: "Inspiring",
          label: "Inspiring",
          disabled: false,
        },
        {
          value: "Knowledgeable",
          label: "Knowledgeable",
          disabled: false,
        },
        {
          value: "Trustworthy",
          label: "Trustworthy",
          disabled: false,
        },
        {
          value: "Familiar",
          label: "Familiar",
          disabled: false,
        },
        {
          value: "Nurturing",
          label: "Nurturing",
          disabled: false,
        },
        {
          value: "Friendly",
          label: "Friendly",
          disabled: false,
        },
        {
          value: "Fun",
          label: "Fun",
          disabled: false,
        },
        {
          value: "Interesting",
          label: "Interesting",
          disabled: false,
        },
      ],
      confidence: "",
      user: null,
      encryptedProjectID: null,
      mapsList: [],
      mapsListBaseline: [],
      loading: false,
      chosenDownloadMaps: [],
      chosenTargetZones: [],
      chosenBaselineMap: [],
      loadingDownload: false,
      originalFormData: {},
      loadingData: false,
      po: null,
      selectAllMaps: false,
      showDownloadModal: false,
      loadingCheckPo: false,
      lastValidPo: null,
      checkedPoValue: null,
      purpose: "",
      isDuplicated: false,
      isAdvancedSetting: false,
    };
  },
  computed: {
    disabledContinueBtn() {
      const isReset = this.actionType === "reset";

      return (
        this.uploadFormData.projectName === "" ||
        this.uploadFormData.projectNumber === "" ||
        this.uploadFormData.mapType === "" ||
        this.uploadFormData.category === "" ||
        this.uploadFormData.market === "" ||
        this.uploadFormData.brands.length === 0 ||
        (!isReset && this.uploadFormData.dateFielded === "") ||
        (!isReset &&
          this.uploadFormData.excelFile === null &&
          this.uploadFormData.zipFile === null)
      );
    },
    disabledDownloadBtn() {
      return (
        this.chosenDownloadMaps.length === 0 ||
        this.confidence === "" ||
        this.purpose === "" ||
        this.po === null ||
        this.po === "" ||
        this.lastValidPo !== this.po
      );
    },
  },
  methods: {
    async uploadFiles(data) {
      if (
        data.file.type === "image/jpeg" ||
        data.file.type === "image/png" ||
        data.file.type === "image/jpg"
      ) {
        this.uploadFormData.imageFile = data.file;
      } else if (data.file.type === "application/zip") {
        try {
          const zip = new JSZip();
          const content = await data.file.arrayBuffer();
          const zipContent = await zip.loadAsync(content);

          let containsSPSS = false;
          let containsExcel = false;

          zipContent.forEach((relativePath) => {
            if (relativePath.endsWith(".sav")) {
              containsSPSS = true;
            }
            if (
              relativePath.endsWith(".xls") ||
              relativePath.endsWith(".xlsx")
            ) {
              containsExcel = true;
            }
          });

          if (containsSPSS && containsExcel) {
            this.uploadFormData.zipFile = data.file;
            this.uploadFormData.excelFile = null;
          } else {
            this.$notification["error"]({
              message: "Error!",
              description: "ZIP file does not contain the required files.",
              placement: "bottomLeft",
              duration: 5,
            });
            this.uploadFormData.zipFile = null;
          }
        } catch (err) {
          this.$notification["error"]({
            message: "Error!",
            description: "Error reading ZIP file.",
            placement: "bottomLeft",
            duration: 5,
          });
          this.uploadFormData.zipFile = null;
        }
      } else {
        this.uploadFormData.excelFile = data.file;
        this.uploadFormData.zipFile = null;
      }
    },
    addBrand() {
      if (this.brand.trim() !== "") {
        this.uploadFormData.brands.push(this.brand.trim());
        this.brand = "";
      }
    },
    removeValue(index) {
      this.uploadFormData.brands.splice(index, 1);
    },
    resetUploadFormData() {
      this.uploadFormData = {
        projectName: "",
        projectNumber: "",
        mapType: "",
        category: "",
        market: "",
        brands: [],
        dateFielded: "",
        imageFile: null,
        excelFile: null,
        zipFile: null,
      };
      this.encryptedProjectID = null;
      this.chosenDownloadMaps = [];
      this.chosenTargetZones = [];
      this.chosenBaselineMap = [];
    },
    save() {
      const isChanged = !_.isEqual(this.uploadFormData, this.originalFormData);

      if (this.disabledContinueBtn) return;

      if (!isChanged) {
        this.step = 2;
        return;
      }

      this.loading = true;
      const date = new Date(
        this.uploadFormData.dateFielded._d || this.uploadFormData.dateFielded
      );
      const formattedDate =
        date.getFullYear() +
        "-" +
        String(date.getMonth() + 1).padStart(2, "0") +
        "-" +
        "01";
      const formData = new FormData();
      formData.append("createdBy", this.user.EncrypteduserID);
      formData.append("projectName", this.uploadFormData.projectName);
      formData.append("projectNumber", this.uploadFormData.projectNumber);
      formData.append("mapType", this.uploadFormData.mapType);
      formData.append("category", this.uploadFormData.category);
      formData.append("market", this.uploadFormData.market);
      formData.append("brands", this.uploadFormData.brands.join(","));
      formData.append("dateFielded", formattedDate);
      formData.append("logoURL", this.uploadFormData.imageFile);
      formData.append(
        "projectID",
        this.chosenProject ? this.chosenProject.projectID : null
      );
      formData.append("dataFileURL", this.uploadFormData.excelFile);
      formData.append("zipFile", this.uploadFormData.zipFile);
      formData.append("IsAdvancedSetting", this.isAdvancedSetting);

      const data = {
        wsName: "CreateMapProject",
        data: {
          form: formData,
        },
      };

      const createProject = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      createProject.then((data) => {
        if (data.success) {
          this.encryptedProjectID = data.encryptedProjectID;
          this.getMaps();
          this.$emit("get-projects");
          this.$notification["success"]({
            message: "Well done!",
            description: "Project created successfully.",
            placement: "bottomLeft",
            duration: 5,
          });
          if (this.actionType === "reset") {
            this.sendGtag("restart_map-maker_project");
          }
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Creation of project failed. Please try again or contact our support.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
      });
    },
    getMaps(projectID, type) {
      const data = {
        wsName: "GetMaps",
        data: {
          projectId: projectID || this.encryptedProjectID,
        },
      };

      const getMaps = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      getMaps.then((data) => {
        if (data.success) {
          this.mapsList = data.message.map((message) => ({
            value: message,
            label: message,
            disabled: false,
          }));
          this.mapsListBaseline = data.message.map((message) => ({
            value: message,
            label: message,
            disabled: true,
          }));
          if (type === "modify") {
            this.chosenDownloadMaps = this.chosenProject.mapSelectedID;
            this.chosenTargetZones = this.chosenProject.targetZones;
            this.chosenBaselineMap.push(this.chosenProject.baseLineMap);
            this.confidence = this.chosenProject.confidence;
            this.po = this.chosenProject.poValue;
            this.purpose = this.chosenProject.purpose;
            this.isDuplicated = this.chosenProject.duplicated;

            this.targetZones.forEach((item) => {
              if (
                this.chosenProject.targetZones.length === 0 ||
                (this.chosenProject.targetZones.length === 1 &&
                  this.chosenProject.targetZones[0] === "")
              ) {
                item.disabled = false;
              } else {
                item.disabled = !this.chosenProject.targetZones.includes(
                  item.value
                );
              }
            });
          }
          this.step = 2;
          this.checkPo();
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Getting maps failed. Please try again or contact our support.",
            placement: "bottomLeft",
            duration: 5,
          });
          this.$emit("close-modal");
        }
        this.loading = false;
        this.loadingData = false;
      });
    },
    setTargetZones(data) {
      if (data.length === 2) {
        this.targetZones = this.targetZones.map((item) => {
          if (!data.includes(item.value)) {
            return { ...item, disabled: true };
          }
          return item;
        });
      } else if (data.length < 2) {
        this.targetZones = this.targetZones.map((item) => {
          return { ...item, disabled: false };
        });
      }

      this.chosenTargetZones = data;
    },
    setDownloadMapsBaseline(data) {
      if (data.length === 1) {
        const selectedValue = data[0];
        this.mapsListBaseline = this.mapsListBaseline.map((item) => {
          return { ...item, disabled: item.value !== selectedValue };
        });
      } else if (data.length === 0) {
        this.mapsListBaseline = this.mapsListBaseline.map((item) => {
          return {
            ...item,
            disabled: !this.chosenDownloadMaps.includes(item.value),
          };
        });
      }

      this.chosenBaselineMap = data;
    },

    download() {
      this.loadingDownload = true;
      this.showDownloadModal = true;

      var AddMapConfigurationAsyncMessage = false;
      var wsNameValue = "AddMapConfiguration";

      if (this.chosenDownloadMaps.length > 15) {
        wsNameValue = "AddMapConfigurationAsync";
        AddMapConfigurationAsyncMessage = true;
      }

      const data = {
        wsName: wsNameValue,
        data: {
          projectId: this.encryptedProjectID || this.chosenProject.projectID,
          mapSelectedID: this.chosenDownloadMaps,
          targetZones: this.chosenTargetZones,
          baseLineMap: this.chosenBaselineMap.join(),
          confidence: this.confidence,
          poValue: this.po,
          purpose: this.purpose,
          userID: this.user.EncrypteduserID,
          duplicated: this.isDuplicated,
        },
      };

      const download = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      download.then((data) => {
        if (data.success) {
          if (this.actionType === "modify") {
            this.sendGtag("update_map-maker");
          }
          if (data.mapPPT !== null) {
            const url = new URL(data.mapPPT);
            const filename = url.pathname.split("/").pop();

            const link = document.createElement("a");
            link.href = data.mapPPT;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          if (AddMapConfigurationAsyncMessage) {
            this.$notification["info"]({
              message: "Preparing files",
              description: data.message,
              placement: "bottomLeft",
              duration: 10,
            });
          }
          this.resetUploadFormData();
          this.showDownloadModal = false;
          this.$emit("close-modal");
          this.$emit("get-projects");
        } else {
          this.showDownloadModal = false;
          this.$emit("close-modal");
          this.$notification["error"]({
            message: "Error!",
            description:
              data.message ||
              "Download failed. Please try again or contact our support.",
            placement: "bottomLeft",
            duration: 10,
          });
        }
        this.loadingDownload = false;
      });
    },
    cancelDownload() {
      const data = {
        wsName: "CancelMapConfiguration",
        data: {
          projectId: this.encryptedProjectID || this.chosenProject.projectID,
          userID: this.user.EncrypteduserID,
        },
      };

      const cancelDownload = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      cancelDownload.then((data) => {
        if (data.Success) {
          this.showDownloadModal = false;
          this.$notification["success"]({
            message: `Request was canceled.`,
            description: "PowerPoint presetation was not generated",
            placement: "bottomLeft",
            duration: 5,
          });
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              data.message ||
              "Canceling Download failed. Please try again or contact our support.",
            placement: "bottomLeft",
            duration: 10,
          });
        }
        this.loadingDownload = false;
      });
    },
    checkActionType() {
      if (this.actionType === "modify") {
        this.loadingData = true;
        this.getMaps(this.chosenProject.projectID, "modify");
      } else if (this.actionType === "reset") {
        this.step = 1;
        this.uploadFormData.projectName = this.chosenProject.projectName;
        this.uploadFormData.projectNumber = this.chosenProject.projectNumber;
        this.uploadFormData.mapType = this.chosenProject.mapType;
        this.uploadFormData.category = this.chosenProject.category;
        this.uploadFormData.market = this.chosenProject.market;
        this.uploadFormData.brands = this.chosenProject.brands;
        this.uploadFormData.dateFielded = this.chosenProject.dateFielded;
      }
    },
    toggleSelectAllMaps() {
      if (this.selectAllMaps) {
        this.chosenDownloadMaps = this.mapsList.map((item) => item.value);
      } else {
        this.chosenDownloadMaps = [];
      }
    },
    // setMaps() {
    //   this.updateSelectAllStatus();
    // },
    updateSelectAllStatus() {
      const allSelected =
        this.chosenDownloadMaps.length === this.mapsList.length;

      this.selectAllMaps = allSelected;
      this.chosenBaselineMap = this.chosenBaselineMap.filter((item) =>
        this.chosenDownloadMaps.includes(item)
      );

      this.mapsListBaseline.forEach((item) => {
        if (this.chosenBaselineMap.length > 0) {
          item.disabled =
            !this.chosenBaselineMap.includes(item.value) ||
            !this.chosenDownloadMaps.includes(item.value);
        } else {
          item.disabled = !this.chosenDownloadMaps.includes(item.value);
        }
      });
    },
    checkPo() {
      if (
        this.lastValidPo === this.po ||
        this.po === null ||
        this.po === "" ||
        this.checkedPoValue === this.po
      )
        return;
      this.loadingCheckPo = true;
      const model = {
        wsName: "ValidatePoValue",
        params: {
          projectId: this.encryptedProjectID || this.chosenProject.projectID,
          poValue: this.po,
        },
      };

      const checkPoValue = new Promise((resolve) => {
        wsUtils.ApiGet(model, resolve);
      });

      checkPoValue.then((data) => {
        this.loadingCheckPo = false;
        if (!data.success) {
          this.$notification["error"]({
            message: "Error!",
            description: data.message,
            placement: "bottomLeft",
            duration: 10,
          });
        } else {
          this.lastValidPo = this.po;
        }
        this.checkedPoValue = this.po;
      });
    },
    sendGtag(eventType) {
      const project = {
        SurveyName: this.chosenProject.projectName,
        SurveyID: this.chosenProject.projectID,
        SurveyTypeName: "Map Maker Library",
      };
      this.trackEvent(eventType, "", project, 1);
    },
  },
  created() {
    this.user =
      JSON.parse(localStorage.getItem("studioUser")) ||
      JSON.parse(jsUtils.readCookie("HS-STUDIO"));
    this.checkActionType();
  },
  watch: {
    step() {
      this.originalFormData = _.cloneDeep(this.uploadFormData);
    },
    chosenDownloadMaps() {
      this.updateSelectAllStatus();
    },
  },
};
</script>

<style lang="scss" scoped>
.add-map {
  ::v-deep .ant-upload.ant-upload-drag {
    padding: 10px 20px;
    border-color: #d4daec;
    border-radius: 12px;
    background: #fff;
    height: 200px;
    .ant-upload-btn {
      border: 0;
    }
  }
  ::v-deep .ant-upload-drag-container {
    svg {
      font-size: 34px;
      color: #7e7e7e;
      margin-bottom: 12px;
      &.fa-check-circle {
        color: #1990ff;
      }
    }
    p {
      font-size: 14px;
      font-weight: 300;
      strong {
        font-weight: 500;
      }
    }
  }
  &__loading {
    position: absolute;
    left: 0;
    top: 55px;
    bottom: 0px;
    width: 100%;
    background: #fff;
    z-index: 99;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-input-group.ant-input-group-compact {
    position: relative;
    .ant-input:first-child {
      border-top-right-radius: 12px !important;
      border-bottom-right-radius: 12px !important;
      padding-right: 45px;
    }
    .ant-btn {
      border-width: 0;
      box-shadow: none !important;
      font-size: 13px;
      position: absolute;
      right: 5px;
      height: auto;
      top: 2px;
      z-index: 1;
      width: auto;
      padding: 8px;
      &[disabled] {
        background: #fff;
        color: #b0afaf !important;
      }
    }
  }
  .input-group ::v-deep .ant-select .ant-select-search {
    margin-top: 0;
  }

  .input-group ::v-deep .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    align-items: flex-start;
    .ant-checkbox {
      top: 1px;
      & + span {
        word-break: break-word;
      }
    }
  }
}
</style>
