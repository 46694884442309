const config = {
  surveyTitle: "Comms Test",
  videoSettingsNextRoute: "comms-creative",
  creativeUploadPevRoute: "comms-setting",
  creativeUploadNextRoute: "comms-key-metrics",
  personaNextRoute: "comms-key-metrics",
  personaPrevRoute: "comms-creative",
  keyMetricsNextRoute: "comms-launch",
  keyMetricsPrevRoute: "comms-creative",
  launchPrevRoute: "comms-key-metrics",
  hideCustomQuestions: true,
  keyMetricsMinBrand: 4,
  keyMetricsMaxBrand: 4,
  keyMetricsMinCogni: 0,
  keyMetricsMaxCogni: 3,
  keyMetricsBrandBrief: "Please enter <b>4</b> competitor brands.",
  keyMetricsBrandRobotText: "Enter 4 competitor brands.",
  keyMetricsCogniBrief: "Please enter up to <b>3</b> key messages.",
  keyMetricsCogniTooltip: "Please enter up to <b>3</b> key messages. These statements are used to evaluate the effectiveness of your creative's functional and rational messaging. They should represent what you want your creative to convey to the consumer.",
  keyMetricsCogniRobotText: "Enter up to 2 key messages.",
  progressBarSteps: [
    {
      title: "General Settings",
      subItems: ["General Study information"],
      icon: {
        name: "IconAdjustments",
        width: "32",
        height: "33",
        viewBox1: "32",
        viewBox2: "33",
        color: "transparent",
      },
      index: 1,
      route: "comms-setting",
    },
    { 
      title: "Creative",
      subItems: ["Creative Submission Details"],
      icon: {
        name: "IconCreative",
        width: "32",
        height: "33",
        viewBox1: "22",
        viewBox2: "23",
        color: "#4318FF",
      },
      index: 2,
      route: "comms-creative",
    },
    // {
    //   title: "Brand Strategy",
    //   subItems: ["Key emotional attributes"],
    //   icon: {
    //     name: "IconPie",
    //     width: "32",
    //     height: "33",
    //     viewBox1: "22",
    //     viewBox2: "23",
    //     color: "transparent",
    //   },
    //   index: 3,
    //   route: "comms-brand-strategy",
    // },
    {
      title: "Key Metrics",
      subItems: ["Brand Analysis"],
      icon: {
        name: "IconMetrics",
        width: "32",
        height: "33",
        viewBox1: "32",
        viewBox2: "33",
        color: "transparent",
      },
      index: 4,
      route: "comms-key-metrics",
    },
    {
      title: "Launch",
      subItems: ["Summary & Confirmation"],
      icon: {
        name: "IconRocket",
        width: "32",
        height: "33",
        viewBox1: "32",
        viewBox2: "33",
        color: "transparent",
      },
      index: 6,
      route: "comms-launch",
    },
  ],
  insertSurveyStimWS: "InsertSurveyStimYoutube",
};

export default config;
