<template>
  <div class="upload">
    <router-link class="upload__back" to="/shelf-builder-library">
      <font-awesome-icon icon="arrow-left" /> Projects list</router-link
    >
    <div
      class="upload__cta"
      v-if="activeUploadForm === null && !showMissingUPC"
    >
      <h1>Ready to move with Shelf Builder?</h1>
      <p>To start, simply upload a CSV file or a PSA file.</p>
      <div>
        <a-button
          type="primary"
          class="b-radius-40"
          @click="activeUploadForm = 'csv'"
          >Upload CSV
        </a-button>
        <i><span>or</span></i>
        <a-button
          type="primary"
          class="b-radius-40"
          @click="activeUploadForm = 'psa'"
          >Upload PSA
        </a-button>
      </div>
    </div>

    <div
      class="upload__inner"
      v-if="activeUploadForm === 'csv' && !showMissingUPC"
    >
      <h2>Upload CSV</h2>
      <a-upload-dragger
        name="file"
        accept=".csv, .zip"
        :multiple="false"
        :showUploadList="false"
        :customRequest="handleCSVUpload"
        ref="upload"
      >
        <div>
          <div class="upload__cta-close" @click="closeUploadForm"></div>
          <font-awesome-icon icon="file-upload" />
          <p>Drag and drop CSV file or browse file on your computer.</p>
        </div>
      </a-upload-dragger>
      <div class="upload-item">
        <div class="upload-item__circle">
          <span
            class="upload-item__circle-label"
            :class="{
              'upload-item__circle-label--enabled': uploadedFile === 'csv',
            }"
            >CSV</span
          >
          <font-awesome-icon icon="file" />
        </div>

        <p>{{ statusMessage }}</p>
        <Loader class="space-0" v-if="uploadingFile === 'csv'" text=""></Loader>
        <font-awesome-icon
          v-else-if="uploadedFile === 'csv'"
          icon="check-circle"
        />
        <font-awesome-icon v-else icon="minus-circle" />
      </div>
    </div>

    <div
      class="upload__inner"
      v-if="
        (activeUploadForm === 'psa' || activeUploadForm === 'zip') &&
        !showMissingUPC
      "
    >
      <h2>Upload {{ psaOrZipLabel }}</h2>
      <a-upload-dragger
        name="file"
        accept=".zip"
        :multiple="false"
        :showUploadList="false"
        :customRequest="psaZipUpload"
        ref="upload"
        :disabled="uploadingFile !== '' || processShelfdataStarted"
      >
        <div>
          <div
            v-if="!showEmailBtn"
            class="upload__cta-close"
            @click="closeUploadForm"
          ></div>
          <font-awesome-icon icon="file-upload" />
          <p v-if="psaOrZipLabel === 'PSA'">
            To begin, simply drag and drop a zip folder containing a PSA file
            and your product image files named by UPC. We're here to help! We
            will try to pull any missing images from our database but please
            review these to ensure they have the most up-to-date packaging.
          </p>
          <p v-else>
            Simply drag and drop {{ psaOrZipLabel }} file or browse file on your
            computer
          </p>
        </div>
      </a-upload-dragger>
      <div class="upload-item">
        <div class="upload-item__circle">
          <span
            class="upload-item__circle-label"
            :class="{
              'upload-item__circle-label--enabled': uploadedFile === 'psa-zip',
            }"
            >{{ psaOrZipLabel }}</span
          >
          <font-awesome-icon icon="file" />
        </div>

        <p>{{ statusMessage }}</p>

        <a-button
          type="primary"
          class="b-radius-40 mr-10"
          @click="sendEmailNotification"
          v-if="showEmailBtn"
        >
          Send Email on Completion
        </a-button>

        <Loader
          class="space-0"
          v-if="uploadingFile === 'psa-zip' || processShelfdataStarted"
          text=""
        ></Loader>
        <font-awesome-icon
          v-else-if="uploadedFile === 'psa-zip' && !processShelfdataStarted"
          icon="check-circle"
        />
        <font-awesome-icon v-else icon="minus-circle" />
        <i
          v-if="uploadingFile === 'psa-zip' || processShelfdataStarted"
          class="upload-item__loading-line"
        ></i>
      </div>
    </div>

    <div class="upload__inner upload__inner--missing-upc" v-if="showMissingUPC">
      <div class="missing-upc">
        <div class="flex justify-between">
          <h2 class="mb-0">Upload Summary Details</h2>

          <div class="flex gap-10">
            <a-button
              type="primary"
              v-if="psaDataFailed && missingFilesType === 'psa'"
              :disabled="processShelfdataStarted"
              class="b-radius-40 dark-disable"
              @click="getCsv"
              ><font-awesome-icon icon="download" class="mr-5" /> Download CSV
            </a-button>
            <a-button
              type="primary"
              :disabled="processShelfdataStarted"
              class="b-radius-40 dark-disable"
              @click="
                (activeUploadForm = 'zip'),
                  (uploadedFile = ''),
                  (showMissingUPC = false)
              "
              ><font-awesome-icon icon="file-upload" class="mr-5" /> Upload ZIP
            </a-button>
            <a-button
              type="dark"
              v-if="missingFilesType === 'psa'"
              :disabled="processShelfdataStarted"
              @click="
                (activeUploadForm = 'csv'),
                  (uploadedFile = ''),
                  (showMissingUPC = false)
              "
              ><font-awesome-icon icon="file-upload" class="mr-5" /> Upload CSV
            </a-button>
            <a-button
              type="dark"
              v-else
              :disabled="processShelfdataStarted"
              @click="
                (activeUploadForm = 'psa'),
                  (uploadedFile = ''),
                  (showMissingUPC = false)
              "
              ><font-awesome-icon icon="file-upload" class="mr-5" /> Upload PSA
            </a-button>

            <a-popover
              :visible="showEmailBtn"
              trigger="click"
              overlayClassName="ant-popover-dark"
            >
              <template #content>
                <a @click="sendEmailNotification">Send Email on Completion</a>
              </template>
              <a-button
                type="dark"
                @click="proceedWithoutImages"
                style="width: 80px"
                :disabled="processShelfdataStarted"
              >
                <Loader
                  class="space-0"
                  text=""
                  v-if="processShelfdataStarted"
                ></Loader>
                <span v-else
                  >Skip <font-awesome-icon icon="arrow-right" class="ml-5"
                /></span>
              </a-button>
            </a-popover>
          </div>
        </div>
        <div class="missing-upc__statistics">
          <div
            class="
              missing-upc__statistics-item missing-upc__statistics-item--db
            "
            :class="{
              'missing-upc__statistics-item--disabled':
                pulledDbUPC.length === 0,
            }"
          >
            <h3>Pulled from database</h3>
            <span>{{ pulledDbUPC.length }}</span>
            <font-awesome-icon icon="check-circle" />
          </div>
          <div
            class="missing-upc__statistics-item"
            :class="{
              'missing-upc__statistics-item--disabled':
                filteredMissingUPC.length === 0,
            }"
          >
            <h3>Total Missing UPC</h3>
            <span>{{ filteredMissingUPC.length }}</span>
            <font-awesome-icon icon="minus-circle" />
          </div>
          <div
            class="
              missing-upc__statistics-item missing-upc__statistics-item--match
            "
            :class="{
              'missing-upc__statistics-item--disabled':
                matchedFiles.length === 0,
            }"
          >
            <h3>Total Matched Files</h3>
            <span>{{ matchedFiles.length }}</span>
            <font-awesome-icon icon="check-circle" />
          </div>
          <div
            class="
              missing-upc__statistics-item missing-upc__statistics-item--unmatch
            "
            :class="{
              'missing-upc__statistics-item--disabled':
                unMatchedFiles.length === 0,
            }"
          >
            <h3>Total Unmatched Files</h3>
            <span>{{ unMatchedFiles.length }}</span>
            <font-awesome-icon icon="exclamation-triangle" />
          </div>
        </div>

        <div class="missing-upc__list">
          <div class="missing-upc__list-cols missing-upc__list-cols--db">
            <div
              class="missing-upc__list-item"
              v-for="item in pulledDbUPC"
              :key="item.UPC"
            >
              {{ item.UPC }}
            </div>
          </div>
          <div class="missing-upc__list-cols">
            <div
              class="missing-upc__list-item"
              v-for="item in filteredMissingUPC"
              :key="item.UPC"
            >
              {{ item.UPC }}
            </div>
          </div>
          <div class="missing-upc__list-cols missing-upc__list-cols--match">
            <div
              class="missing-upc__list-item"
              v-for="item in matchedFiles"
              :key="item.FileName"
            >
              {{ item.FileName }}
            </div>
          </div>
          <div class="missing-upc__list-cols missing-upc__list-cols--unmatch">
            <div
              class="missing-upc__list-item"
              v-for="item in unMatchedFiles"
              :key="item.FileName"
            >
              {{ item.FileName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/general/Loader.vue";
import * as wsUtils from "@/utils/wsUtils.js";
import { gtagMixin } from "@/mixins/gtagMixin";

export default {
  name: "ShelfUpload",
  components: {
    Loader,
  },
  props: {
    shelfId: {
      type: String,
    },
    userId: {
      type: String,
    },
  },
  mixins: [gtagMixin],
  data() {
    return {
      activeUploadForm: null,
      missingUPC: [],
      unMatchedFiles: [],
      matchedFiles: [],
      uploadedFile: "",
      uploadingFile: "",
      processShelfdataStarted: false,
      showMissingUPC: false,
      showEmailBtn: false,
      stopGetProcessStatus: false,
      psaDataFailed: false,
      missingFilesType: null,
    };
  },
  computed: {
    statusMessage() {
      if (this.uploadingFile !== "") return "Uploading...";
      if (this.uploadedFile !== "" && !this.processShelfdataStarted)
        return "File is uploaded...";
      if (this.processShelfdataStarted)
        return "Processing data, please wait...";
      return "File is not uploaded...";
    },
    psaOrZipLabel() {
      return this.activeUploadForm === "psa" ? "PSA" : "ZIP";
    },
    pulledDbUPC() {
      return this.missingUPC.filter((item) => item.DBLink !== "");
    },
    filteredMissingUPC() {
      return this.missingUPC.filter((item) => item.DBLink === "");
    },
  },
  methods: {
    closeUploadForm() {
      if (
        this.missingUPC.length > 0 ||
        this.unMatchedFiles.length > 0 ||
        this.matchedFiles.length > 0
      ) {
        this.showMissingUPC = true;
      }
      this.activeUploadForm = null;
    },
    handleCSVUpload(data) {
      if (data.file.type === "text/csv") {
        this.csvUpload(data);
      } else {
        this.csvZipUpload(data);
      }
    },
    csvUpload(data) {
      this.uploadedFile = "";
      this.uploadingFile = "csv";
      let form = new FormData();
      form.append("file", data.file);
      form.append("surveyId", this.shelfId);
      form.append("userId", this.userId);
      form.append("shelfType", "Control");

      const getShelfRenderDetails = new Promise((resolve) => {
        wsUtils.GetShelfRenderDetails(
          {
            form,
          },
          resolve
        );
      });

      getShelfRenderDetails.then((response) => {
        if (!response.success) {
          this.$notification["error"]({
            message: "CSV Upload Failed.",
            description: response.message,
            placement: "bottomLeft",
            duration: 5,
          });
          this.uploadedFile = "";
        } else {
          this.sendGtag("uploaded_csv");
          this.uploadedFile = "csv";

          setTimeout(() => {
            this.$emit("csv-uploaded", response.message);
          }, 500);
        }
        this.uploadingFile = "";
      });
    },
    csvZipUpload(data) {
      this.uploadedFile = "";
      this.uploadingFile = "csv";
      let form = new FormData();
      form.append("file", data.file);
      form.append("surveyId", this.shelfId);
      form.append("userId", this.userId);
      form.append("shelfType", "Control");

      let payload = {
        wsName: "UploadProductCSVZIP",
        data: {
          form,
        },
      };

      const csvZipUpload = new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(payload, resolve);
      });

      csvZipUpload.then((response) => {
        this.processPsaCSVUpload(response, "csv");
        this.uploadingFile = "";
        this.uploadedFile = "csv";
      });
    },
    sendGtag(actionType) {
      const project = {
        SurveyName: decodeURIComponent(this.$route.params.projectName),
        SurveyID: this.shelfId,
        SurveyTypeName: "Shelf Builder",
      };
      this.trackEvent("shelf_builder", actionType, project, 1);
    },
    proceedWithoutImages() {
      this.processShelfdata().then(() => {
        setTimeout(() => {
          this.stopGetProcessStatus = false;
          this.getProcessStatus("skipUpload");
        }, 2000);
      });
    },
    psaZipUpload(file) {
      this.uploadedFile = "";
      this.uploadingFile = "psa-zip";
      let form = new FormData();
      form.append("file", file.file);
      form.append("surveyId", this.shelfId);
      form.append("shelfType", "Control");

      if (this.activeUploadForm !== "zip") {
        form.append("userId", this.userId);
      }

      let data = {
        wsName:
          this.activeUploadForm === "zip"
            ? "UploadProductZIP"
            : "UploadProductPSAZIP",
        data: {
          form,
        },
      };
      const psaUpload = new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(data, resolve);
      });

      psaUpload.then((response) => {
        this.uploadingFile = "";
        this.uploadedFile = "psa-zip";
        if (this.activeUploadForm === "zip") {
          this.processZipUpload(response);
        } else {
          this.processPsaCSVUpload(response, "psa");
          this.sendGtag("uploaded_psa");
        }
      });
    },
    getProcessStatus(type) {
      if (this.stopGetProcessStatus) return;

      let form = new FormData();
      form.append("surveyId", this.shelfId);
      form.append("shelfType", "Control");

      let data = {
        wsName: "GetProcessStatus",
        data: {
          form,
        },
      };

      let showBtnTimeout = setTimeout(() => {
        if (!this.stopGetProcessStatus) {
          this.showEmailBtn = true;
        }
      }, 8000);

      const getProcessStatus = new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(data, resolve);
      });

      getProcessStatus.then((response) => {
        clearTimeout(showBtnTimeout);

        if (response.ProcessFinished === false) {
          if (!this.stopGetProcessStatus) {
            this.showEmailBtn = true;
          }
          setTimeout(() => {
            if (type === "skipUpload") {
              this.getProcessStatus(type);
            } else {
              this.getProcessStatus();
            }
          }, 8000);
        } else if (this.missingUPC.length === 0 || type === "skipUpload") {
          this.showEmailBtn = false;
          this.$emit("get-project-details");
          this.processShelfdataStarted = false;
        } else {
          this.showEmailBtn = false;
          this.matchFiles();
          this.processShelfdataStarted = false;
        }
      });
    },
    matchFiles() {
      this.matchedFiles.forEach((item) => {
        const fileNameWithoutExtension = item.FileName.split(".")[0];

        this.missingUPC = this.missingUPC.filter(
          (item1) => item1.UPC !== fileNameWithoutExtension
        );
      });
      this.showMissingUPC = true;
    },
    processShelfdata(skipLoading) {
      if (!skipLoading) {
        this.processShelfdataStarted = true;
      }
      let form = new FormData();
      form.append("surveyId", this.shelfId);
      form.append("shelfType", "Control");

      let data = {
        wsName: "ProcessShelfdata",
        data: {
          form,
        },
      };

      return new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(data, resolve);
      }).then((data) => {
        console.log("processShelfdata completed", data);
      });
    },
    processPsaCSVUpload(response, type) {
      this.missingUPC = response.MissingProduct;
      this.unMatchedFiles = response.UnMatchedFiles;
      this.matchedFiles = response.MatchedFiles;
      this.checkPSAData(response.PSACompilation);

      if (response.Message) {
        this.$notification["warning"]({
          message: `Oops`,
          description: response.Message,
          placement: "bottomLeft",
          duration: 8,
        });
      }

      setTimeout(() => {
        if (this.missingUPC.length === 0 && response.success) {
          this.processShelfdata().then(() => {
            setTimeout(() => {
              this.stopGetProcessStatus = false;
              this.getProcessStatus();
            }, 2000);
          });
        } else if (!response.success) {
          this.uploadedFile = "";
          this.$notification["error"]({
            message: `Oops! Something went wrong with your upload.`,
            description:
              "There was an error with your upload. Please try again. If it keeps happening, contact support for help.",
            placement: "bottomLeft",
            duration: 6,
          });
        } else {
          this.missingFilesType = type;
          this.processShelfdata(true);
          this.showMissingUPC = true;
        }
      }, 500);
    },
    processZipUpload(response) {
      this.unMatchedFiles.push(...response.UnMatchedFiles);
      this.matchedFiles.push(...response.MatchedFiles);
      if (response.Message) {
        this.$notification["warning"]({
          message: `Oops`,
          description: response.Message,
          placement: "bottomLeft",
          duration: 8,
        });
      }

      this.processShelfdata().then(() => {
        setTimeout(() => {
          this.stopGetProcessStatus = false;
          this.getProcessStatus();
        }, 2000);
      });
    },
    checkPSAData(obj) {
      this.psaDataFailed = false;
      for (let key in obj) {
        if (
          Object.prototype.hasOwnProperty.call(obj, key) &&
          obj[key] === false
        ) {
          this.$info({
            title: "Oops",
            class: "ant-modal--dark",
            content: () => (
              <div>
                We were unable to find and map important information from the
                PSA. Please download the CSV to check what information is
                missing and to configure it manually.
              </div>
            ),
            onOk() {},
            okText: "Ok",
            onCancel() {},
          });
          this.psaDataFailed = true;
          break;
        }
      }
    },
    sendEmailNotification() {
      let form = new FormData();
      form.append("surveyId", this.shelfId);
      form.append("URL", window.location.href);
      form.append("shelfType", "Control");

      let data = {
        wsName: "ProcessShelfdataEmailRequired",
        data: {
          form,
        },
      };
      const emailNotification = new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(data, resolve);
      });

      emailNotification.then((response) => {
        if (response.Success) {
          this.stopGetProcessStatus = true;
          this.$notification["success"]({
            message: `Send Email on Completion`,
            description:
              "You will receive an email notification once the uploading process is completed.",
            placement: "bottomLeft",
            duration: 6,
          });
          this.processShelfdataStarted = false;
          this.showEmailBtn = false;
          this.$router.push({
            name: "shelf-builder-library",
          });
        } else {
          this.$notification["error"]({
            message: `Oops! Registration Error.`,
            description:
              "There was an issue registering for the email notification after completion of the upload process. Please try again. If it keeps happening, contact support for help.",
            placement: "bottomLeft",
            duration: 6,
          });
        }
      });
    },
    getCsv() {
      console.log("this.$route.params.cipId", this.$route.params.projectName);
      const exportPSAtoCSV = new Promise((resolve) => {
        wsUtils.ExportPSAtoCSV({ SurveyId: this.shelfId }, resolve);
      });

      exportPSAtoCSV.then((data) => {
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = href;
        let fileName = `${this.$route.params.projectName
          .replace(/ /g, "_")
          .replace(/_+$/, "")}.csv`;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },
    reset() {
      this.showMissingUPC = null;
      this.activeUploadForm = null;
      this.psaDataFailed = false;
      this.uploadedFile = "";
      this.missingUPC = [];
    },
  },
  activated() {
    this.reset();
  },
};
</script>
<style lang="scss" scoped>
.upload {
  display: flex;
  align-items: center;
  padding: 70px 0;
  text-align: center;
  flex-direction: column;
  color: #fff;
  border-radius: 4px;
  &__inner {
    max-width: 500px;
    width: 100%;
    &--missing-upc {
      max-width: 800px;
    }
    h2 {
      text-align: left;
      color: #fff;
      font-weight: 400;
      font-size: 24px;
    }
  }
  ::v-deep .ant-upload {
    background: #1f2022;
    color: #dcd9d9;
    padding: 20px;
    border-color: #68696a;
    border-radius: 12px;
    margin-bottom: 20px;
    height: 270px;
    .ant-upload-btn {
      margin-bottom: 0;
      height: 100%;
    }
    &.ant-upload-disabled {
      opacity: 0.6;
    }
  }
  ::v-deep .dark-disable {
    &[disabled] {
      color: #fff !important;
      background-color: #1890ff !important;
      border-color: #1890ff !important;
      opacity: 0.5;
    }
  }
  ::v-deep .ant-upload-drag-container {
    font-size: 18px;
    svg {
      font-size: 45px;
      color: #7e7e7e;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
    }
  }

  &__back {
    left: 35px;
    top: 30px;
    color: #9e9e9e;
    position: absolute;
    z-index: 1;
    &:hover {
      color: #cecece;
    }
    svg {
      margin-right: 4px;
    }
  }

  &__cta {
    display: flex;
    flex-direction: column;
    padding: 70px 0;
    h1 {
      color: #fff;
      margin-bottom: 5px;
      font-size: 42px;
    }
    p {
      margin-bottom: 30px;
      font-size: 21px;
      color: #d5d5d5;
    }
    .ant-btn {
      width: 170px;
      height: 60px;
      font-size: 20px;
    }
    i {
      position: relative;
      margin: 10px 0;
      &:after {
        position: absolute;
        left: 24px;
        right: 24px;
        border-bottom: 1px solid #333438;
        content: "";
        top: 12px;
      }
      span {
        position: relative;
        z-index: 1;
        background: #1a1b1d;
        padding: 0 20px;
        color: #aeacac;
      }
    }
  }
  &__cta-close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 1;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #7e7e7e;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
    &:hover {
      &:before,
      &:after {
        background-color: #1890ff;
      }
    }
  }
}

.missing-upc {
  &__statistics {
    display: flex;
    gap: 10px;
    z-index: 1;
    margin-bottom: 10px;
    position: sticky;
    top: -20px;
    background: #1a1b1d;
    padding-top: 20px;
  }
  &__statistics-item {
    border-radius: 10px;
    text-align: left;
    color: #fff;
    background: #1f2022;
    flex: 1;
    padding: 10px 10px 10px 17px;
    position: relative;
    overflow: hidden;
    border: 1px solid #2a2b2e;
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-right: 5px solid #e00000;
      content: "";
    }
    &--disabled {
      opacity: 0.55;
    }
    &--match {
      &:after {
        border-right-color: #01c101;
      }
    }
    &--unmatch {
      &:after {
        border-right-color: #808080;
      }
    }
    &--db {
      &:after {
        border-right-color: yellow;
      }
    }
    h3 {
      color: #fff;
      font-size: 14px;
      margin-bottom: 17px;
    }
    span {
      font-size: 30px;
      font-weight: 700;
      display: block;
      line-height: normal;
    }
    svg {
      right: 12px;
      bottom: 12px;
      font-size: 24px;
      color: #3f3f42;
      position: absolute;
    }
  }
  &__list {
    display: flex;
    gap: 10px;
  }
  &__list-cols {
    flex: 1;
    &--match {
      .missing-upc__list-item {
        &:after {
          border-right-color: #01c101;
        }
      }
    }
    &--unmatch {
      .missing-upc__list-item {
        &:after {
          border-right-color: #808080;
        }
      }
    }
    &--db {
      .missing-upc__list-item {
        &:after {
          border-right-color: yellow;
        }
      }
    }
  }
  &__list-item {
    padding: 10px 10px 10px 17px;
    margin-bottom: 8px;
    background: linear-gradient(
      to right,
      rgba(40, 40, 44, 1) 31%,
      rgba(29, 30, 32, 1) 100%
    );
    text-align: left;
    border-radius: 10px;
    font-size: 14px;
    position: relative;
    overflow: hidden;
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-right: 5px solid #e00000;
      content: "";
    }
  }
}

.upload-item {
  background: #1f2022;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 14px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0;
  }
  &__circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #303234;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    position: relative;
    svg {
      font-size: 14px;
    }
  }
  &__circle-label {
    position: absolute;
    left: -2px;
    font-size: 8px;
    top: -2px;
    background: #464749;
    color: #fff;
    padding: 1px 3px;
    border-radius: 3px;
    display: block;
    line-height: normal;
    &--enabled {
      background: #1890ff;
    }
  }
  &__loading-line {
    width: 100%;
    height: 3px;
    background-color: #2e2f31;
    border-radius: 20px;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      left: -50%;
      height: 3px;
      width: 50%;
      background-color: #1890ff;
      animation: lineAnim 1.5s linear infinite;
    }
  }
  p {
    color: #dadada;
    margin: 0;
    font-size: 14px;
    margin-right: auto;
  }
  .fa-check-circle {
    font-size: 21px;
    color: var(--blue);
  }
  .fa-minus-circle {
    font-size: 21px;
    color: #464749;
  }
}

@keyframes lineAnim {
  0%,
  100% {
    left: -60%;
  }
  50% {
    left: 110%;
  }
}
</style>
